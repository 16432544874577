<template>
  <v-dialog v-model="show" max-width="650" persistent>
    <v-card>
      <v-container>
        <v-row>
          <v-col>
              <OrganizzeModalHeader
                :loading="modal.loading"
                :isEdit="modal.isEdit"
                :isDuplicate="modal.duplicar"
                :title="modal.isEdit ? record.description + recorrenceDescription : recordType.name"
                @onClose="closeModal()"
                class="m-title"
              />

              <v-text-field
              class="mt-2"
                v-if="record.type !== 'T'"
                label="Descrição"
                v-model="record.description"
                :error-messages="recordDescriptionErrors"
                :disabled="modal.loading"
                @blur="$v.record.description.$touch()"
                outlined>
              </v-text-field>

              <v-row class="limit-row" >
                <v-col cols="12" sm="4" md="4" lg="4" class="pb-0 pt-0">
                  <v-currency-field
                    label="Valor"
                    v-model="record.value"
                    prefix="R$"
                    :error-messages="recordValueErrors"
                    :disabled="modal.loading"
                    @blur="$v.record.value.$touch()"
                    @input="$v.record.value.$touch()"
                    outlined
                  />
                </v-col>

                <v-col cols="12" sm="4" md="4" lg="4" class="pb-0 pt-0">
                  <v-menu
                    transition="slide-y-transition"
                    :close-on-content-click="true"
                    offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dtEmissionLabel"
                        :label="record.type === 'T' ? 'Data da transferência' : 'Data da Emissão'"
                        append-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        :disabled="modal.loading || record.jsonFlags !== false"
                        readonly
                        outlined>
                      </v-text-field>
                    </template>

                    <v-date-picker
                      v-model="record.dtEmission"
                      :color="variables.colorPrimary"
                      :disabled="record.jsonFlags !== false"
                      no-title
                      scrollable>
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" sm="4" md="4" lg="4" class="pb-0 pt-0" v-if="record.type !== 'T'">
                  <v-menu
                    transition="slide-y-transition"
                    :close-on-content-click="true"
                    offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dtDueLabel"
                        label="Data de Vencimento"
                        append-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        :disabled="modal.loading"
                        readonly
                        outlined>
                        <OrganizzeReactionMenu
                          :title="wasPaid ? 'Efetivado' : 'Pendente'"
                          :liked="!wasPaid"
                          @onLike="wasPaid = false"
                          @onUnlike="wasPaid = true"
                          slot="append-outer"
                        />
                      </v-text-field>
                    </template>

                    <v-date-picker
                      v-model="record.dtDue"
                      no-title
                      scrollable
                      :min="record.dtEmission"
                      :color="variables.colorPrimary">
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <v-row class="limit-row" v-if="wasPaid && record.type !== 'T'" no-gutters>
                <v-col>
                  <v-menu
                    transition="slide-y-transition"
                    :close-on-content-click="true"
                    offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dtPaidLabel"
                        label="Data do pagamento"
                        append-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        :disabled="modal.loading"
                        readonly
                        outlined>
                      </v-text-field>
                    </template>

                    <v-date-picker
                      v-model="record.dtPaid"
                      no-title
                      scrollable
                      :min="record.dtEmission"
                      :color="variables.colorPrimary">
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col style="margin-left: 15px;">
                  <v-currency-field
                    label="Multa"
                    v-model="record.interestValue"
                    prefix="R$"
                    :disabled="modal.loading || record.jsonFlags !== false"
                    outlined
                  />
                </v-col>

                <v-col style="margin-left: 15px;">
                  <v-currency-field
                    label="Juros"
                    v-model="record.fineValue"
                    prefix="R$"
                    :disabled="modal.loading || record.jsonFlags !== false"
                    outlined
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="6" sm="6" class="pb-0 pt-0">

                  <template v-if="record.type === 'T'">
                    <v-autocomplete
                      v-model="record.accountIdFrom"
                      item-text="name"
                      item-value="id"
                      append-icon="mdi-magnify"
                      :label="record.type === 'T' ? 'Saiu da conta' : 'Escolha uma conta'"
                      :error-messages="recordAccountIdFromErrors"
                      :items="accountList"
                      :disabled="modal.loading"
                      @blur="$v.record.accountIdFrom.$touch()"
                      clearable
                      outlined
                      filled
                      dense>
                      <template v-slot:item="data">
                        <v-list-item-avatar>
                          <img
                            :src="(
                              (data.item.Institutions || {}).icon
                              || 'https://e7.pngegg.com/pngimages/456/851/png-clipart-wallet-computer-icons-wallet-brown-leather-thumbnail.png'
                            )"
                          />
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title v-html="data.item.name"></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>

                    <v-autocomplete
                      v-model="record.accountIdTo"
                      label="Entrou na conta"
                      item-text="name"
                      item-value="id"
                      append-icon="mdi-magnify"
                      style="margin-left: 15px;"
                      :error-messages="recordAccountIdToErrors"
                      :items="accountList"
                      :disabled="modal.loading"
                      @blur="$v.record.accountIdTo.$touch()"
                      clearable
                      outlined
                      filled
                      dense>
                      <template v-slot:item="data">
                        <v-list-item-avatar>
                          <img
                            :src="(
                              (data.item.Institutions || {}).icon
                              || 'https://e7.pngegg.com/pngimages/456/851/png-clipart-wallet-computer-icons-wallet-brown-leather-thumbnail.png'
                            )"
                          />
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title v-html="data.item.name"></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </template>

                  <template v-else>
                    <v-autocomplete
                      v-model="record.accountId"
                      item-text="name"
                      item-value="id"
                      append-icon="mdi-magnify"
                      :label="record.type === 'T' ? 'Saiu da conta' : 'Escolha uma conta'"
                      :error-messages="recordAccountIdErrors"
                      :items="accountList"
                      :disabled="modal.loading"
                      @blur="$v.record.accountId.$touch()"
                      clearable
                      outlined
                      filled
                      dense>
                      <template v-slot:item="data">
                        <v-list-item-avatar>
                          <img
                            :src="(
                              (data.item.Institutions || {}).icon
                              || 'https://e7.pngegg.com/pngimages/456/851/png-clipart-wallet-computer-icons-wallet-brown-leather-thumbnail.png'
                            )"
                          />
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title v-html="data.item.name"></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </template>                  
                </v-col>

                <v-col class="pb-0 pt-0">
                  <v-autocomplete
                    v-model="record.recordCategoryId"
                    label="Escolha uma categoria"
                    item-text="name"
                    item-value="id"
                    append-icon="mdi-magnify"
                    id="teste"
                    :error-messages="recordRecordCategoryIdErrors"
                    :items="categoriasPorLancamentos()"
                    :disabled="modal.loading"
                    @blur="$v.record.recordCategoryId.$touch()"
                    clearable
                    outlined
                    filled
                    dense>
                    <template v-slot:item="data">
                      <v-list-item-icon style="margin-right: 8px;">
                        <v-icon :color="data.item.colour" v-if="!data.item.categoryId">mdi-arrow-right-bold-circle</v-icon>
                        <v-icon :color="'#FFF'" v-else></v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.name"></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>
                  <!-- :style="{ 'margin-left': record.type === 'T' ? '0px' : '15px' }" -->
              </v-row>
            
              <v-row no-gutters class="mb-3" v-if="record.jsonFlags !== false" >
                <v-col class=" pl-3 pt-3 pb-3 pr-3" style="background: #fff7dd">
                  <h4 class="mb-3 mt-2" style="color: " > Este lançamento se repete em outras datas. </h4>
                    <v-checkbox class="mt-0 pt-0 checkbox" v-model="record.typeUpdated" :label="`Atualizar apenas este lançamento`" value="now" ></v-checkbox>
                    <v-checkbox class="mt-0 pt-0 checkbox" v-model="record.typeUpdated" :label="`Atualizar este e os próximos`" value="nextAll" ></v-checkbox>
                    <v-checkbox class="mb-0 mt-0 pt-0 pb-0 checkbox" v-model="record.typeUpdated" :label="`Atualizar todos os lançamentos`" value="all" ></v-checkbox>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="col-12 d-flex text-center justify-content-center pb-0 pt-0">
                  <button
                    class="optionsButton"
                    :disabled="modal.loading"
                    @click="modal.showOptions = !modal.showOptions">
                    {{`${modal.showOptions ? '- Esconder' : '+ Mostrar'} opções`}}
                  </button>
                </v-col>
              </v-row>

              
                <v-row  v-if="modal.showOptions">
                  <v-col>
                    <v-row no-gutters class="limit-row">
                      <v-combobox
                        v-model="selectedTags"
                        :items="tagList"
                        :disabled="modal.loading"
                        item-value="id"
                        item-text="name"
                        label="Tags"
                        multiple
                        outlined
                        dense
                        chips
                      />
                    </v-row>

                    <v-row v-if="record.type !== 'T'">
                      <v-col class="pb-0 pt-0 mr-0" cols="12" sm="6" md="6">
                        <v-autocomplete
                        
                          v-model="record.costCenterId"
                          label="Centro(s) de custo(s)"
                          item-text="name"
                          item-value="id"
                          :items="costCenterList"
                          :disabled="modal.loading"
                          clearable
                          outlined
                          filled
                          dense>
                          <template v-slot:item="data">
                            <v-list-item-content>
                              <v-list-item-title v-html="data.item.name"></v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </v-autocomplete>
                      </v-col>

                      <v-col class="pb-0 pt-0 ml-0">
                        <v-autocomplete
                          v-model="record.contactId"
                          :label="record.type === 'D' ? 'Fornecedor' : 'Cliente'"
                          item-text="name"
                          item-value="id"
                          :items="record.type === 'D' ? contactList : customerContactList"
                          :disabled="modal.loading"
                          clearable
                          outlined
                          filled
                          dense
                          :autofocus="modal.recibo"
                          :error-messages="contactIdErrors"
                        >

                          <template v-slot:item="data">
                            <v-list-item-content>
                              <v-list-item-title v-html="data.item.name"></v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </v-autocomplete>
                      </v-col>

                    </v-row>

                    <v-row v-if="record.type !== 'T'" no-gutters>
                      <v-file-input
                      class="mb-2"
                        accept="image/*"
                        label="Anexo"
                        v-model="selectedAttachments"
                        :disabled="modal.loading"
                        show-size
                        counter
                        outlined
                        clearable
                        multiple
                        filled
                        dense
                        small-chips>
                        <template v-slot:selection="{ index }">
                          <v-chip
                            color="deep-purple accent-4"
                            dark
                            label
                            small
                            @click="onDownloadFile($event, index)">
                            {{ selectedAttachments[index].name }}

                            <v-icon
                              v-if="selectedAttachments[index].name_s3"
                              style="margin-left: 15px;">
                              mdi-cloud-download-outline
                            </v-icon>

                            <v-icon
                              style="margin-left: 15px;"
                              @click="onDeleteFile($event, index)">
                              mdi-close
                            </v-icon>
                          </v-chip>
                        </template>
                      </v-file-input>
                    </v-row>

                    <v-row no-gutters>
                      <v-textarea class="mb-0 pb-0"
                          label="Informações adicionais"
                          v-model="record.memo"
                          :disabled="modal.loading"
                          :aria-multiline="true"
                          rows="1"
                          outlined>
                        </v-textarea>
                    </v-row>

                    <!-- Repeticao -->
                    <v-row v-if="record.type !== 'T' && record.jsonFlags === false">
                      <v-col class="pb-0 pt-0">
                        <h4 > Repetir </h4>
                          <v-checkbox class="mt-0 pt-0 checkbox" v-model="parcelamento.type" :label="`Receita ou despesa fixa`" value="1" ></v-checkbox>
                          <v-checkbox class="mb-0 mt-0 pt-0 pb-0 checkbox" v-model="parcelamento.type" :label="`Receita ou despesa parcelada`" value="2" ></v-checkbox>
                      </v-col>
                    </v-row>

                    <v-row v-if="record.jsonFlags === false">
                      <!-- Parcelamento FIXO -->
                      <v-col class="col-6 pb-0 pt-0" v-if="parcelamento.type === '1'">
                        <v-autocomplete 
                          v-model="parcelamento.fixo"
                          :items="parcelamentoFixoSelect"
                          return-object
                          :label="'Escolha tipo fixo'"
                        />
                      </v-col>

                      <v-col class="col-6 pt-0" v-if="parcelamento.type === '1'">
                        <v-autocomplete 
                          v-model="parcelamento.qtd"
                          :items="selectQtd"
                          :label="'Qtd. repetições'"
                        />
                      </v-col>
                      
                      <!-- Parcelamento Parcelado -->
                      <v-col class="col-6 pb-0 pt-0" v-if="parcelamento.type === '2'">
                        <v-autocomplete 
                          v-model="parcelamento.parcelado"
                          :items="parcelamentoFixoSelect"
                          return-object
                          :label="'Escolha opção de parcelamento'"
                        />
                      </v-col>

                      <v-col class="col-6 pt-0" v-if="parcelamento.type === '2'">
                        <v-autocomplete 
                          v-model="parcelamento.qtd"
                          :items="selectQtd"
                          :label="'Qtd. repetições'"
                        />
                      </v-col>

                    </v-row>
                  </v-col>
                </v-row>

              <OrganizzeModalFooter class="mt-2" :loading="modal.loading" @onConfirm="enviar()"/>
          </v-col>

        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import Events from '@/core/service/events'
import { required, requiredIf, minValue } from 'vuelidate/lib/validators'
import validation from './../../../financeiro/lancamentos/validation'
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
// eslint-disable-next-line
import { errorSnackbar, successSnackbar } from '@/core/service/utils'
import { recordsTypesEnum } from '@/utils/tempUtils'
import { filter, map, flatten  } from 'lodash'
import variables from '@/assets/styles/helpers/_variables.scss'
import { some, range } from 'lodash'
const namespaceStore = 'organizzeFinanceiroLancamentos'

export default {
  name: 'CategoriasLancamentos',
  props: ['show'],
  directives: {},
  components: {
    OrganizzeModalHeader: () => import('@/views/components/organizze/modal/header'),
    OrganizzeReactionMenu: () => import('@/views/components/organizze/reactionMenu'),
    OrganizzeModalFooter: () => import('@/views/components/organizze/modal/footer')
  },
  data: () => ({
    recorrenceDescription: '',
    parcelamentoFixoSelect: [],
    modal: {
      toggle: false,
      loading: false,
      isEdit: false,
      showOptions: false,
      hasTag: false,
      duplicar: false,
      recibo: false
    },
    recordType: {
      id: null,
      name: 'Despesa',
      type: 'D'
    },
    selectQtd: range(2, 38),
    parcelamento: {
      type: null,
      fixo: {},
      parcelado: {},
      qtd: null
    },
    record: {
      id: null,
      accountId: null,
      accountIdTo: null,
      recordCategoryId: null,
      contactId: null,
      value: 0.0,
      status: 'P',
      type: null,
      description: '',
      memo: '',
      costCenterId: null,
      dtEmission: moment().format('YYYY-MM-DD'),
      dtDue: moment().format('YYYY-MM-DD'),
      dtPaid: moment().format('YYYY-MM-DD'),
      interestValue: 0.0,
      fineValue: 0.0,
      tags: [],
      attachments: [],
      transaction: null,
      attachmentsToDelete: [],
      jsonFlags: false,
      typeUpdated: null
    },
    dtEmissionLabel: moment().format('DD/MM/YYYY'),
    dtDueLabel: moment().format('DD/MM/YYYY'),
    dtPaidLabel: moment().format('DD/MM/YYYY'),
    wasPaid: false,
    selectedTags: [],
    selectedAttachments: [],
    backupAttachments: [],
    block: false
  }),
  created () {},
  mounted () {
    const self = this

    self.parcelamentoFixoSelect = self.generateLabelsFixo()
    
    Events.$on('modalCadastro::open', val => {
      self.modal.toggle = true
      self.recordType = {
        id: val.recordType.id,
        name: val.recordType.name,
        type: val.recordType.type
      }
      self.record.type = self.recordType.type
    })

    Events.$on('modalCadastro::edit', (val) => {
      self.record.jsonFlags = false
      let name = val.name
      self.modal.toggle = true
      self.modal.isEdit = true
      self.parcelamento.type = null
      self.parcelamento.fixo = {}
      self.parcelamento.parcelado = {}

      if (name === 'duplicar') {
        self.modal.duplicar = true
      }

      if (name === 'recibo') {
        self.modal.recibo = true
        self.modal.showOptions = true
      }

      if (self.block) return

      self.recordType = filter(recordsTypesEnum(), item => item.type === val.record.type)[0]
      /* trata descricao para nunca alterar (atual/qtd) na descricao */
      self.recorrenceDescription = ''
      let descricao = val.record.jsonFlags && val.record.jsonFlags.parentId ? String(val.record.description).split('(') : val.record.description
      descricao = typeof descricao === 'string' ? descricao : String(descricao[0]).trim()
      if (val.record.jsonFlags && val.record.jsonFlags.parentId) {
        self.recorrenceDescription = val.record.description.split('(')
        self.recorrenceDescription = ` (${self.recorrenceDescription[self.recorrenceDescription.length - 1]}`
      }
      self.record = {
        id: val.record.id,
        accountId: val.record.Accounts.id,
        accountIdTo: val.record.accountIdTo,
        accountIdFrom: val.record.accountIdFrom,
        recordCategoryId: (val.record.RecordCategories || {}).id,
        contactId: (val.record.Contacts || {}).id,
        value: Math.abs(parseFloat(val.record.value).toFixed(2)),
        status: val.record.status,
        type: val.record.type,
        description: descricao,
        memo: val.record.memo,
        costCenterId: (val.record.CostCenters || {}).id,
        dtEmission: moment(val.record.dtEmission).format('YYYY-MM-DD'),
        dtDue: moment(val.record.dtDue).format('YYYY-MM-DD'),
        dtPaid: moment(val.record.dtPaid || moment()).format('YYYY-MM-DD'),
        interestValue: val.record.interestValue,
        fineValue: val.record.fineValue,
        tags: (() => flatten(map(val.record.RecordTags, recordTag => map(recordTag.tag, tag => tag))))(),
        attachments: [],
        transaction: val.record.transaction,
        attachmentsToDelete: [],
        jsonFlags: val.record.jsonFlags || false
      }

      self.dtEmissionLabel = moment(val.record.dtEmission).format('DD/MM/YYYY'),
      self.dtDueLabel = moment(val.record.dtDue).format('DD/MM/YYYY'),
      self.dtPaidLabel = moment(val.record.dtPaid || moment()).format('DD/MM/YYYY'),
      self.wasPaid = val.record.status === 'E'
      self.selectedTags = self.record.tags
      self.selectedAttachments = val.record.Attachments
      self.backupAttachments = val.record.Attachments
      self.record.typeUpdated = val.record.jsonFlags ? 'now' : null
      self.block = true
    })

    Events.$on('modalCadastro::edit::lancamentoCategorias', val => {
      self.modal.toggle = true
      self.modal.isEdit = true
      console.log('val', val)
    })
  },

  computed: {
    ...mapGetters(namespaceStore, [
      'accountList',
      'tagList',
      'costCenterList',
      'expenditureCategoryList',
      'incomeCategoryList',
      'otherCategoryList',
      'contactList',
      'customerContactList'
    ]),
    ...validation,
    variables: () => variables
  },

 watch: {
    'record.dtEmission' () {
      const self = this
      self.dtEmissionLabel = moment(self.record.dtEmission).format('DD/MM/YYYY')
      if (self.record.dtDue < self.record.dtEmission) self.record.dtDue = self.record.dtEmission
    },

    'record.dtDue' () {
      const self = this
      self.dtDueLabel = moment(self.record.dtDue).format('DD/MM/YYYY')
    },

    'record.dtPaid' () {
      const self = this
      self.dtPaidLabel = moment(self.record.dtPaid).format('DD/MM/YYYY')
    },

    'wasPaid' () {
      const self = this
      if (self.wasPaid) self.record.status = 'E'
      else self.record.status = 'P'
    },

    'selectedAttachments' (val) {
      const self = this

      if (self.record.attachments.length > 0) {
        const toAdd = filter(val, item => !some(self.record.attachments, attachment => item.name === attachment.name))

        if (toAdd.length > 0) {
          self.selectedAttachments = self.record.attachments.concat(toAdd)
          self.record.attachments = self.record.attachments.concat(toAdd)
          return
        }
      }

      self.record.attachments = []

      if (val.length === 0 || filter(val, item => !item.type.match('image.*')).length !== 0) return

      for (const item of val) {
        if (!item.name_s3) {
          const reader = new FileReader()
          reader.onload = (e) => self.record.attachments.push({
            parentId: self.record.id,
            name: item.name,
            name_s3: item.name_s3,
            type: item.type,
            data: e.target.result,
            folder_s3: '/records'
          })
          reader.readAsDataURL(item)
        } else {
          self.record.attachments.push(item)
        }
      }
    },

    'record.value' () {
      const self = this
      self.record.value = Math.abs(self.record.value)
    },

    'record.interestValue' () {
      const self = this
      self.record.interestValue = Math.abs(self.record.interestValue)
    },

    'record.fineValue' () {
      const self = this
      self.record.fineValue = Math.abs(self.record.fineValue)
    }
  },
  methods: {
    ...mapActions(namespaceStore, ['submit', 'downloadImage']),
    generateLabelsFixo () {
      return [
        { text: 'Anual', key: 'years' },
        { text: 'Semestral', key: 'month', d: 6 },
        { text: 'Trimestral', key: 'quarter', d: 3 },
        { text: 'Bimestral', key:'month', d: 2 },
        { text: 'Mensal', key: 'month' },
        { text: 'Quinzenais', key: 'days', d: 15 },
        { text: 'Semanal', key: 'weeks' },
        { text: 'Diárias', key: 'days' }
      ]
    },
    onReact (val) {
      const self = this
      self.wasPaid = !val
    },

    enviar () {
      const self = this

      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }

      self.modal.loading = true

      if (!self.wasPaid) self.record.dtPaid = null
      self.record.tags = map(self.selectedTags, selectedTag => {
        if (typeof(selectedTag) === 'string') return { name: selectedTag }
        else return selectedTag
      })

      if (self.record.type === 'T') self.record.description = 'Transferência'

      if (self.record.type === 'T') self.record.accountId = self.record.accountIdFrom

      if(self.record.typeUpdated === 'now') {
        self.record.description = `${self.record.description} ${self.recorrenceDescription}`
      }

      const data = {
        record: self.record,
        isEdit: true,
        isTransfer: self.record.type === 'T',
        isRepeat: self.parcelamento.type ? self.parcelamento : false,
        isCopy: self.modal.duplicar || false, // duplica lancamento existente
        typeUpdated: self.record.typeUpdated || false
      }

      self.submit(data).then(() => {
        if (!self.modal.isEdit) {
          successSnackbar('Lançamento adicionado com sucesso.')
        } else {
          successSnackbar('Lançamento editado com sucesso.')

          if (self.modal.recibo) {
            self.getRecords({ _addlFilter: { 'Records:id_=': data.record.id } }).then(record => {
              Events.$emit('categorias::emitirRecibo::modal', { record: record[0] })
              self.modal.loading = false
              self.closeModal()
            })
          } else {
            Events.$emit('categorias::update::tables')
            Events.$emit('OrganizzeFilter::buscar')
            self.closeModal()
            self.modal.loading = false
          }
        }
        
      }).catch(e => {
        console.error(e)
        errorSnackbar(e)
       }).finally(() => {
         self.modal.loading = false
      })
    },

    closeModal () {
      const self = this
      self.block = false
      Events.$emit('close::modal::categoria')
      self.modal = {
        toggle: false,
        loading: false,
        isEdit: false,
        showOptions: false,
        hasTag: false,
        recibo: false
      }

      self.recordType = {
        id: null,
        name: '',
        type: null
      }

      self.record = {
        id: null,
        accountId: null,
        accountIdFrom: null,
        accountIdTo: null,
        recordCategoryId: null,
        contactId: null,
        value: 0.0,
        status: 'P',
        type: null,
        description: '',
        memo: '',
        costCenterId: null,
        dtEmission: moment().format('YYYY-MM-DD'),
        dtDue: moment().format('YYYY-MM-DD'),
        dtPaid: moment().format('YYYY-MM-DD'),
        interestValue: 0.0,
        fineValue: 0.0,
        tags: [],
        attachments: [],
        transaction: null,
        attachmentsToDelete: []
      }

      self.dtEmissionLabel = moment().format('DD/MM/YYYY'),
      self.dtDueLabel = moment().format('DD/MM/YYYY'),
      self.dtPaidLabel = moment().format('DD/MM/YYYY'),
      self.wasPaid = false
      self.selectedTags = []
      self.selectedAttachments = []
      self.backupAttachments = []
    },

    expenditureCategoryListGrouped (listCategories) {
      const categories = listCategories
      const categoriesParent = categories.filter(item => !item.categoryId)
      const categoriesParentChildren = categoriesParent.flatMap(item => {
        const categoriesChildren = categories.filter(sitem => sitem.categoryId === item.id).flatMap(sitem => ({
              id: sitem.id,
              name: sitem.name,
              categoryId: sitem.categoryId,
              colour: sitem.colour,
              disabled: sitem.jsonFlags && sitem.jsonFlags.InportOnly 
            }))
        return [ { id: item.id, name: item.name, categoryId: item.categoryId, colour: item.colour, disabled: true }, ...categoriesChildren ]
      })
      return categoriesParentChildren
    },

    categoriasPorLancamentos () {
      const self = this
      switch (self.recordType.type) {
        case 'D': return self.expenditureCategoryListGrouped(self.expenditureCategoryList)
        case 'R': return self.expenditureCategoryListGrouped(self.incomeCategoryList)
        default: return self.expenditureCategoryListGrouped(self.otherCategoryList)
      }
    },

    isDiffValue () {
      const self = this
      return self.record.type === 'T' ? self.record.accountIdFrom !== self.record.accountIdTo : true
    },

    async onDownloadFile (event, index) {
      const self = this
      event.stopPropagation()
      self.downloadImage(self.record.attachments[index])
    },

    onDeleteFile (event, index) {
      const self = this
      event.stopPropagation()
      if (self.selectedAttachments[index].name_s3 !== undefined) self.record.attachmentsToDelete.push(self.selectedAttachments[index])
      self.selectedAttachments.splice(index, 1)
    }
  },
  validations () {
    const self = this
    return {
      record: {
        description: { required: requiredIf(() => self.record.type !== 'T') },
        value: { required, minValue:  minValue(0.01) },
        accountId: { required: requiredIf(() => self.record.type !== 'T') },
        accountIdFrom: { required: requiredIf(() => self.record.type === 'T'), isSameValue: self.isDiffValue},
        accountIdTo: { required: requiredIf(() => self.record.type === 'T'), isSameValue: self.isDiffValue},
        recordCategoryId: { required: requiredIf(() => self.record.type !== 'T') },
        contactId: { required: requiredIf(() => self.modal.recibo) }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '~@/assets/styles/helpers/_variables.scss';

 .v-card { border-radius: 16px !important; }

  .optionsButton {
    color: white;
    padding: 6px;
    background-color: $colorAccent;
    border-radius: 16px;
    margin: 0 auto;
  }
  div.limit-row {
    max-height: 55px;
    @media(max-width: 768px) {
      max-height: initial;
    }
    .v-input::v-deep .v-input__control .v-input__slot {
        min-height: 45px;
    }
    .v-input::v-deep .v-input__append-inner{
      margin-top: 10px
    }
    .v-input::v-deep .v-input__append-outer{
      margin-top: 12px
    }
    .checkbox::v-deep .v-input__control .v-input__slot{
      margin-bottom: 0;
    }
  }
  .checkbox{
    max-height: 25px;
  }
 
  .v-text-field.v-text-field--enclosed .v-text-field__details{
    margin: 0;
    padding: 10px 10px;
  }
  .v-application--is-ltr .v-messages{
    color: red
  }
</style>